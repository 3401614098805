<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#"> {{ $t("Edite") }} {{ $t("products") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ data.namear }} </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main" id="main">
      <div class="head">
        <div class="head1">
          <h4>{{ $t("Edite") }} {{ $t("product") }} - {{ data.namear }}</h4>
        </div>
        <div class="head2">
          <button @click="submit">{{ $t("save") }}</button>
        </div>
      </div>
      <div class="body">
        <div class="body2" id="main2">
          <div class="form1">
            <br />
            <h5>{{ $t("details") }}</h5>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("ar") }}
              </label>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="data.namear" />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("en") }}
              </label>

              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="data.nameen" />
              </div>
            </div>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("sn") }}
              </label>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" disabled v-model="data.sn" />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("item") }}
              </label>
              <div class="col-sm-4" style="display: flex;">
                <input class="form-control form-control-sm" id="inputEmail3" disabled v-model="data.item" />
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  {{ $t("Edite") }}
                </button>

                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        </h5>
                      </div>
                      <div class="modal-body">
                        <div class="row mb-3">
                          <label for="inputEmail3" class="col-sm-2 col-form-label-sm">
                            {{ $t("item") }}
                          </label>
                          <div class="col-sm-4">
                            <v-select :options="data1" label="category" v-model="item">
                              <template #option="option">
                                <span>{{ option.category }}</span>
                              </template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="changeitem">
                          {{ $t("save") }}
                        </button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                          {{ $t("cancel") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("unit type") }}
              </label>

              <div class="col-sm-4">
                <select class="form-control form-control-sm" id="inputEmail3" v-model="data.unittype">
                  <option v-for="val in unit" :key="val" :value="val.unit">
                    {{ $t(val.unit) }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("barcode") }}
              </label>

              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" disabled
                  v-model="data.barcode" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-sm-2">
                <button class="btn btn-primary" type="submit" @click="print">
                  <i class="bi bi-printer-fill"></i> {{ $t("printe") }}
                </button>
              </div>
              <div class="col-sm-3" id="barcode">
                <div style="direction: rtl">
                  <vue3-barcode :value="data.barcode" :height="25" />
                  <div style="
                      font-size: smaller;
                      direction: right;
                      font-weight: bold;
                    ">
                    {{ this.$store.state.cnum[0].cname }} - {{ data.namear }} -
                    {{ data.salep }}
                  </div>
                </div>
                <br />
              </div>
            </div>
            <hr />
            <h5>{{ $t("accounting") }}</h5>
            <!--  -->
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("tax") }} %
              </label>
              <div class="col-sm-2">
                <input type="number" class="form-control form-control-sm" id="inputEmail3" v-model="data.tax" />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("saleprice") }}</label>

              <div class="col-sm-1">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="data.salep" />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("Account") }} {{ $t("sales") }}

              </label>

              <div class="col-sm-4">
                <v-select :options="buylist" label="namear" v-model="salec">
                  <template #option="option">
                    <span>{{ option.namear }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("buyprice") }}
              </label>

              <div class="col-sm-1">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="data.buyp" />
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("Account") }} {{ $t("cost") }}
              </label>

              <div class="col-sm-4">
                <v-select :options="selllist" label="namear" v-model="buyc">
                  <template #option="option">
                    <span>{{ option.namear }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("sale") }} {{ $t("discount") }}
              </label>

              <div class="col-sm-1">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="data.discount" />
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("minimum") }} {{ $t("balance") }} {{
                $t("stock") }}
              </label>

              <div class="col-sm-1">
                <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="data.minstock" />
              </div>
            </div>
            <br /><br />
            <!-- 
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-1 col-form-label-sm"
                >{{ $t("image") }} {{ $t("product") }}
              </label>

              <div class="col-sm-5">
                <div id="preview">
                  <img :src="this.url" crossorigin="anonymous" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import Vue3Barcode from "vue3-barcode";
import html2pdf from "html2pdf.js";
import vSelect from "vue-select";
export default {
  async mounted() {
    const data2 = await axios.get(`/data/productview/${this.$route.params.id}`);
    const result2 = await axios.get("/data/allunittypes");
    const result = await axios.get("/data/allproductcategoriesparent");
    const result3 = await axios.get("/data/accountingmanual");
    if (data2.status == 200) {
      this.data1 = result.data.data;
      const data = data2.data.data[0];
      this.unit = result2.data.data;
      this.selllist = result3.data.data;
      this.buylist = result3.data.data;
      this.data = data;
      this.rawmaterial = JSON.parse(data.rawmaterial);
      this.url = store.state.imgurl + `/products/${this.data.sn}.png`;
      this.salec = { namear: data.snamear, nameen: data.snameen, actnum: data.salec }
      this.buyc = { namear: data.bnamear, nameen: data.bnameen, actnum: data.buyc }
    }
  },
  props: ["css"],
  components: {
    Vue3Barcode,
    vSelect
  },
  data() {
    return {
      v$: useValidate(),
      data: "",
      data1: "",
      rawmaterial: "",
      url: "",
      namear: "",
      nameen: "",
      sn: "",
      item: "",
      description: "",
      unittype: "",
      barcode: "",
      tax: "",
      salep: "",
      salec: "",
      snamear: "",
      snameen: "",
      buyp: "",
      buyc: "",
      bnamear: "",
      bnameen: "",
      discount: "",
      stock: "",
      rawmaterialcost: "",
      unit: "",
    };
  },
  validations() {
    return {
      data: { required },
    };
  },
  methods: {
    changeitem() {
      this.data.item = this.item.category
    },
    async submit() {
      const result = await axios.post("/data/productedite", {
        username: store.state.randp.username,
        namear: this.data.namear,
        nameen: this.data.nameen,
        description: this.data.description,
        tax: this.data.tax,
        salep: this.data.salep,
        buyp: this.data.buyp,
        discount: this.data.discount,
        stock: this.data.stock,
        minstock: this.data.minstock,
        unittype: this.data.unittype,
        id: this.$route.params.id,
        item: this.item.category
      });
      if (result.status == 200) {
        // let formData = new FormData();
        // formData.append("file", this.file);
        // axios.patch(`/upload/${this.sn}`, formData);
        this.redirectTo({ val: "AllProducts" });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (result.status == 401) {
        alert("Account Already Created Before");
      }
    },
    pdf() {
      html2pdf(document.getElementById("barcode").innerHTML, {
        margin: 0.2,
        filename: `barcode.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print() {
      var divElements = document.getElementById("barcode").innerHTML;
      var WinPrint = window.open();
      WinPrint.document.write(
        "<html><head><title>Barcode</title></head><body>" +
        divElements +
        "</body>"
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    copy() {
      if (this.copy == true) {
        this.streetsh = this.street;
        this.citysh = this.city;
        this.areash = this.area;
        this.zipcodesh = this.zipcode;
        this.buildingsh = this.building;
        this.countrysh = this.country;
      } else {
        this.streetsh = "";
        this.citysh = "";
        this.areash = "";
        this.zipcodesh = "";
        this.buildingsh = "";
        this.countrysh = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 2%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
