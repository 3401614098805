<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allproperties">{{ $t("all") }}
              {{ $t("projects") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allproperties" class="active">{{ $t("add") }}
              {{ $t("project") }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            {{ $t("add") }} {{ $t("project") }}
            {{ $t("new") }}
          </h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submite">
            {{ $t("save") }}
          </button>
        </div>
      </div>
      <br />
      <div class="body">
        <div class="body1">
          <div class="group">
            <label for="basic-url" class="form-label">{{ $t("name") }} {{ $t("project") }}
            </label>
            &nbsp;
            <span class="error-feedback">{{ "*" }}</span>
            <div>
              <input type="text" v-model="name" />
            </div>
          </div>
          <!-- <div class="group">
            <label for="basic-url" class="form-label">{{ $t("image") }} </label>
            <div style="display: flex">
              <input type="file" @change="onFileChange" style="width: 50%" />
              <div id="preview">
                <img v-if="url" :src="url" />
              </div>
            </div>
          </div> -->
        </div>
        <hr />
        <div class="body3">
          <div class="selection">
            <button class="" :class="select1 ? 'active' : ''" @click="
              (this.select1 = true),
              (this.select2 = false),
              (this.select3 = false),
              (this.select4 = false),
              (this.select5 = false)
              ">
              {{ $t("description") }}
            </button>
            <button class="" :class="select3 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = false),
              (this.select3 = true),
              (this.select4 = false),
              (this.select5 = false)
              ">
              {{ $t("spaces") }}
            </button>
            <button class="" :class="select4 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = false),
              (this.select3 = false),
              (this.select4 = true),
              (this.select5 = false)
              ">
              {{ $t("Accounts") }}
            </button>
            <!-- <button class="" :class="select5 ? 'active' : ''" @click="
              (this.select1 = false),
              (this.select2 = false),
              (this.select3 = false),
              (this.select4 = false),
              (this.select5 = true)
              ">
              {{ $t("Commissions") }}
            </button> -->
          </div>
          <div class="group2" v-if="select1 == true">
            <div class="part">
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">{{ $t("owner") }}
                  <span class="error-feedback" v-if="v$.owner.$error">{{
                    "*"
                  }}</span></label>
                <div class="col-sm-7">
                  <v-select :options="owners" label="name" v-model="owner">
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.name }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">{{ $t("category") }} {{ $t("realty") }}</label>
                <div class="col-sm-7">
                  <v-select :options="categories" label="category" v-model="catitem">
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.category }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">{{ $t("type") }} {{ $t("project") }}</label>
                <div class="col-sm-7">
                  <select type="number" class="form-control" v-model="ptype">
                    <option value="inhouse">مشروعات تحت التنفيذ</option>
                    <option value="other">مشروعات للغير</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">{{ $t("Commission") }} {{ $t("management") }} {{ $t("projects")
                  }} %
                </label>
                <div class="col-sm-7">
                  <!-- <input type="number" class="form-control" v-model="commission" /> -->
                  <select type="number" class="form-control" v-model="commission">
                    <option value="6">6%</option>
                    <option value="9">9%</option>
                    <option value="15">15%</option>
                    <option value="20">20%</option>
                    <option value="0">بدون عمولة</option>
                  </select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">مساحة الارض
                </label>
                <div class="col-sm-7">
                  <input type="number" class="form-control" v-model="landspace" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">مساحة البناء
                </label>
                <div class="col-sm-7">
                  <input type="number" class="form-control" v-model="buildspace" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">نسبة التنفيز
                </label>
                <div class="col-sm-7">
                  <input type="number" class="form-control" v-model="spaceper" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">قيمة الارض
                </label>
                <div class="col-sm-7">
                  <input type="number" class="form-control" v-model="landvalue" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">المخطط
                </label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" v-model="addressnum" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">سعر متر البناء
                </label>
                <div class="col-sm-7">
                  <input type="number" class="form-control" v-model="spacecost" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">اجمالى تكلفة البناء
                </label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" v-model="totalcost" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">تاريخ البدا
                </label>
                <div class="col-sm-7">
                  <input type="date" class="form-control" v-model="start" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">تاريخ الانتهاء
                </label>
                <div class="col-sm-7">
                  <input type="date" class="form-control" v-model="end" />
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label"> مدة العقد
                </label>
                <div class="col-sm-4">
                  <input type="number" class="form-control" v-model="totaltime" />

                </div>
                <label class="col-sm-2 col-form-label"> شهر
                </label>
              </div>
              <!-- <div class="row mb-1">
                <label class="col-sm-2 col-form-label"> معدل العائد
                </label>
                <div class="col-sm-4">
                  <input type="number" class="form-control" v-model="rateofreturn" />
                </div>
              </div> -->
            </div>
            <div class="part">
              <GoogleMap api-key="AIzaSyDFT8pm_ORjQHfxKsTnBb1c6hM-HVvoN6Q" style="width: 100%; height: 300px"
                :center="center" :zoom="15">
                <Marker :options="markerOptions" />
              </GoogleMap>
            </div>
          </div>
          <div class="group2" v-if="select2 == true">
            <div class="part">
              <div class="mb-3 row">
                <div class="mb-1 row">
                  <label for="inputPassword" class="col-sm-2 col-form-label">نوع الوثيقة</label>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="doctype" />
                  </div>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="inputPassword" class="col-sm-2 col-form-label">تاريخ الاصدار</label>
                <div class="col-sm-10">
                  <input type="date" class="form-control" id="inputPassword" v-model="docdate" />
                </div>
              </div>
            </div>

            <div class="part">
              <div class="mb-3 row">
                <label for="inputPassword" class="col-sm-2 col-form-label">
                  رقم وثيقة
                </label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" id="inputPassword" v-model="docnum" />
                </div>
              </div>
            </div>
          </div>
          <div class="body4" v-if="select2 == true">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>المالك</th>
                  <th>الحصة</th>
                  <th>رقم الحساب</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, id) in docdata" :key="x">
                  <td>{{ id + 1 }}</td>
                  <td><input type="text" v-model="x.owner" /></td>
                  <td><input type="text" v-model="x.percent" /></td>
                  <td><input type="text" v-model="x.owneracct" /></td>
                  <td>
                    <a href="#" @click.prevent="del2(id)"><i class="bi bi-x-circle-fill"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <button @click="this.docdata.push({})">اضافة</button>
          </div>
          <div class="group3" v-if="select3 == true">
            <div class="part">
              <div class="input-group mb-1">
                <span class="input-group-text">مساحة الارض</span>
                <input type="text" class="form-control" v-model="totalspace.land" />
                <span class="input-group-text" id="basic-addon2">متر مربع</span>
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">المساحة التاجيرية</span>
                <input type="text" class="form-control" v-model="totalspace.rentspace" />
                <span class="input-group-text" id="basic-addon2">متر مربع</span>
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">مسطح البناء</span>
                <input type="text" class="form-control" v-model="totalspace.flatbuild" />
                <span class="input-group-text" id="basic-addon2">متر مربع</span>
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">عدد الطوابق</span>
                <input type="text" class="form-control" v-model="totalspace.flatnum" />
              </div>
            </div>
            <div class="part">
              <div class="input-group mb-1">
                <span class="input-group-text"> المخطط</span>
                <input type="text" class="form-control" v-model="totalspace.scheme" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> قطعة رقم</span>
                <input type="text" class="form-control" v-model="totalspace.placenum" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> سعر البيع</span>
                <input type="text" class="form-control" v-model="totalspace.salep" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text">المدينة</span>
                <input type="text" class="form-control" v-model="totalspace.city" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> الحي</span>
                <input type="text" class="form-control" v-model="totalspace.aria" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> الرمز البريدى</span>
                <input type="text" class="form-control" v-model="totalspace.zip" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> رقم المبنى </span>
                <input type="text" class="form-control" v-model="totalspace.buildnum" />
              </div>
              <div class="input-group mb-1">
                <span class="input-group-text"> اسم الشارع </span>
                <input type="text" class="form-control" v-model="totalspace.street" />
              </div>
            </div>
          </div>
          <div class="body4" v-if="select3 == true">
            <h4>الحدود و الاطوال</h4>
            <table class="table" style="width: 50%">
              <thead>
                <tr>
                  <th>الجهه</th>
                  <th>الحد</th>
                  <th>بطول</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>شمال</td>
                  <td><input type="text" v-model="totalspace.northl" /></td>
                  <td><input type="text" v-model="totalspace.northh" /></td>
                </tr>
                <tr>
                  <td>جنوب</td>
                  <td><input type="text" v-model="totalspace.southl" /></td>
                  <td><input type="text" v-model="totalspace.southh" /></td>
                </tr>
                <tr>
                  <td>شرق</td>
                  <td><input type="text" v-model="totalspace.eastl" /></td>
                  <td><input type="text" v-model="totalspace.easth" /></td>
                </tr>
                <tr>
                  <td>غرب</td>
                  <td><input type="text" v-model="totalspace.westl" /></td>
                  <td><input type="text" v-model="totalspace.westh" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="group2" v-if="select4 == true">
            <div class="part">
              <div class="row mb-1">
                <label class="col-sm-2 col-form-label">حساب عهد المشروعات
                  <span class="error-feedback" v-if="v$.account.$error">{{
                    "*"
                  }}</span></label>
                <div class="col-sm-7">
                  <v-select :disabled="disabled2" :options="accountpayment" label="namear" v-model="account">
                    <template #option="option">
                      <div class="option">
                        <span>{{ option.namear }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="group2" v-if="select5 == true">
            <div class="part">
              <h5>العمولات</h5>
              <div style="width: 25%">
                <v-select :options="AllCommissions" label="name" v-model="allcommission">
                  <template #option="option">
                    <div style="text-align: center">
                      <span>{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>العمولة</th>
                    <th>حساب العمولة</th>
                    <th>نسبة العمولة</th>
                    <th>مبلغ العمولة</th>
                    <th>حساب الضريبة</th>
                    <th>ضريبة العمولة</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(y, id) in commissions" :key="y">
                    <td style="width: 20%">
                      {{ y.name }}
                    </td>
                    <td style="width: 30%">
                      <v-select label="namear" v-model="y.act" disabled>
                        <template #option="option">
                          <span>{{ option.namear }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td>
                      <div style="display: flex">
                        <input type="text" v-model="y.num" />&nbsp;%
                      </div>
                    </td>
                    <td>
                      <div style="display: flex; width: 120%">
                        <input type="text" v-model="y.comamount" />
                      </div>
                    </td>
                    <td style="width: 30%">
                      <v-select :options="allaccounts" label="namear" v-model="y.taxact" disabled>
                        <template #option="option">
                          <span>{{ option.namear }}</span>
                        </template>
                      </v-select>
                    </td>
                    <td style="display: flex">
                      <div style="display: flex">
                        <input type="text" v-model="y.tax" @change="
                          y.amount = Number(
                            (this.unite.annualrent * y.num) / 100 +
                            (((this.unite.annualrent * y.num) / 100) *
                              y.tax) /
                            100
                          ).toFixed(2)
                          " />&nbsp;%
                      </div>
                    </td>

                    <td>
                      <a href="#" @click.prevent="delcom(id)"><i class="bi bi-x-circle-fill"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import vSelect from "vue-select";
import { GoogleMap, Marker } from "vue3-google-map";
import moment from "moment";
export default {
  props: ["css"],
  components: {
    vSelect,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      cnum: store.state.cnum[0].cnum,
      disabled: false,
      disabled2: false,
      v$: useValidate(),
      url: "",
      select1: true,
      select2: false,
      select3: false,
      select4: false,
      select5: false,
      center: { lat: 31.2902956, lng: 31.6900514 },
      name: "",
      prodata: [{}],
      totalspace: {},
      owners: [],
      owner: "",
      categories: [
        { category: "مستودع", value: "storehouse" },
        { category: "مصانع", value: "Factories" },
        { category: "ورشة", value: "workshop" },
        { category: "مباسط", value: "mabasit" },
        { category: "عمارة", value: "architecture" },
      ],
      catitem: {},
      commission: "",
      docnum: "",
      docdate: "",
      payment: "",
      notes: "",
      file: "",
      account: {

      },
      allcommission: "",
      commissiontype: "",
      buildspace: "",
      landspace: "",
      spaceper: "",
      landvalue: "",
      addressnum: "",
      spacecost: "",
      totalcost: "",
      start: "",
      end: "",
      totaltime: "",
      ptype: "",
      rateofreturn: ""
    };
  },
  validations() {
    return {
      name: { required },
      account: { required },
      commission: { required },
      owner: { required },
    };
  },
  async mounted() {
    const result = await axios.get("/data/customerowner/owner");
    if (result.status == 200) {
      this.owners = result.data.data;
    }
    const result2 = await axios.get("/data/getaccounttrue");
    if (result2.status == 200) {
      this.accountpayment = result2.data.data;
    }
    // const resul3 = await axios.get("/data/AllCommissions");
    // if (resul3.status == 200) {
    //   this.AllCommissions = resul3.data.data;
    // }
    const check = this.$store.state.userPermissions.includes('accounting_module')
    if (check == false) {
      this.disabled2 = true
      this.taxaccount = {
        actnum: "2105", namear: "ضريبة القيمة المضافة المستحقة", nameen: "VAT payable", typeact: "credit"
      }
      this.account = {
        actnum: "210101",
        namear: "الدائنون",
        nameen: "الدائنون",
        typeact: "credit",
      }
      this.commissionaccount = { actnum: "4101", namear: "إيرادات المبيعات/ الخدمات", nameen: "Revenue of Products and services Sales", typeact: "credit" }
    }
  },
  async created() { },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    async del(id) {
      this.prodata.splice(id, 1);
    },
    async del2(id) {
      this.docdata.splice(id, 1);
    },
    async submite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          this.disabled = true;
          const result = await axios.post(`/data/createnewproject`, {
            username: store.state.randp.username,
            name: this.name,
            ownername: this.owner.name,
            ownerid: this.owner.cid,
            catitem: this.catitem.value,
            commission: this.commission,
            type: this.type,
            landspace: this.landspace,
            buildspace: this.buildspace,
            spaceper: this.spaceper,
            totalspace: JSON.stringify(this.totalspace),
            account: JSON.stringify(this.account),
            landvalue: this.landvalue,
            addressnum: this.addressnum,
            spacecost: this.spacecost,
            totalcost: this.totalcost,
            start: this.start,
            end: this.end,
            totaltime: this.totaltime,
            ptype: this.ptype,
            rateofreturn: this.rateofreturn
          });
          if (result.status == 200) {
            this.redirectTo({ val: "allprojects" });
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Data Entry successfully",
            });
          } else if (result.status == 401) {
            alert("Account Already Created Before");
          }
        } catch (error) {
          alert("Error");
        }
      }
    },
    async delcom(id) {
      this.commissions.splice(id, 1);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    buildspace() {
      this.spaceper = Number((this.buildspace / this.landspace) * 100).toFixed(2)
    },
    landspace() {
      this.spaceper = Number((this.buildspace / this.landspace) * 100).toFixed(2)
    },
    spacecost() {
      this.totalcost = Number(this.spacecost * this.buildspace).toFixed(2)
    },
    start() {
      const date1 = moment(this.start);
      const date2 = moment(this.end);
      this.totaltime = date2.diff(date1, 'months');
    },
    end() {
      const date1 = moment(this.start);
      const date2 = moment(this.end);
      this.totaltime = date2.diff(date1, 'months');
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 50%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}
</style>
