<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("projects") }} & {{ $t("classification") }}</a>
          </li>
          <li>-</li>
          <li>
            <a>{{ $t("classification") }} </a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("classification") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("classification") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <br />
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("name") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-5">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="name" />
            </div>
          </div>
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-1 col-form-label-sm">{{ $t("description") }}
              <span class="error-feedback" v-if="v$.description.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-5">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="description"></textarea>
            </div>
          </div>





          <div class="buttom">
            <button class="buttonconfirm" type="submit" @click="submit">
              {{ $t("save") }}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button class="buttoncancel" type="submit" @click="back">
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      projects: [],
      name: "",
      code: "",
      description: "",
      dueon: "",
      privat: "",
      priority: "",
      project: "",
      projectname: "",
      projectid: "",
      r2: store.state.randp.r2,
    };
  },
  validations() {
    return {
      name: { required },
      description: { required },
    };
  },
  async mounted() {
    // if (this.r3 == false) {
    //   this.redirectTo({ val: "Home" });
    // }
    // // const result = await axios.get("/data/lasttasknum");
    // // if (result.status == 200) {
    // //   this.code = "TASK-" + (Number(result.data.data[0].count) + Number(1));
    // // }
    // const result2 = await axios.get("/data/allprojects");
    // if (result.status == 200) {
    //   this.projects = result2.data.data;
    // }
  },
  methods: {
    back() {
      this.$root.$router.push({
        path: "/AdminDashboard/AllTasks",
      });
    },
    async checkname() {
      const result = await axios.get(`/data/productcheckname/${this.name}`);
      if (result.status == 200) {
        const data = result.data.data;
        if (data.length > 0) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      }
    },
    async submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post("/data/createnewtask", {
          username: store.state.randp.username,
          name: this.name,
          description: this.description,
          due: this.dueon,
          private: this.privat,
          users: "",
          priority: this.priority,
          projectname: this.projectname,
          projectid: this.projectid,
        });
        if (result.status == 200) {
          this.redirectTo({ val: "AllTasks" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    filter() {
      this.data = this.data2.filter((x) =>
        x.category.includes(this.filter.toLowerCase())
      );
    },
    project() {
      this.projectname = this.project.name;
      this.projectid = this.project.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
