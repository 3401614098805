<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="/property/allpayment" class="active">{{ $t("orders") }} {{ $t("paid")
              }} {{
                $t("extracts") }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <!-- <input type="text" :placeholder="$t('name')" /> -->
          <input type="text" :placeholder="$t('number') + ' ' + $t('receipt')" v-model="recnum" />
          <input type="date" :placeholder="$t('date') + ' ' + $t('extract')" v-model="paydate" />
          <input type="text" :placeholder="$t('project')" v-model="realty" />
          <input type="text" :placeholder="$t('contractor')" v-model="renter" />
          <input type="text" :placeholder="$t('extract')" v-model="extract" />
          <button style="padding: 1%" @click="search()">
            {{ $t("search") }}
          </button>
        </div>
        <div class="head2">
          <button>
            <router-link to="/ProjectsManagement/AllExtractsPayment/CreateReceiptProject">+ {{ $t("add") }} {{
              $t("paid") }} {{
                $t("extracts") }}
            </router-link>
          </button>
        </div>
      </div>
      <div class="body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("receipt") }}</th>
              <th>{{ $t("date") }} {{ $t("payment") }}</th>
              <th>{{ $t("project") }}</th>
              <th>{{ $t("contractor") }}</th>
              <th>{{ $t("number") }} {{ $t("extract") }}</th>
              <th>{{ $t("total") }}</th>
              <th>{{ $t("type") }} {{ $t("payment") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("status") }}</th>
              <th v-if="hasPermission('receipt_voucher_delete')">{{ $t("delete") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in data" :key="val">
              <td>
                <router-link :to="`/ProjectsManagement/AllExtractsPayment/ReceiptProject/${val.recid}`"
                  class="active">{{ val.recid }}</router-link>
              </td>
              <td>{{ val.paydate }}</td>
              <td>
                <router-link :to="`/ProjectsManagement/AllExtractsPayment/ReceiptProject/${val.recid}`"
                  class="active">{{ val.proname }}</router-link>
              </td>
              <td>{{ val.ownername }}</td>
              <td>{{ val.ownerid }}</td>
              <td>{{ Number(val.amount).toFixed(2) }}</td>
              <td>{{ val.paytype }} <span v-if="val.ownerpayment">من عهدة المالك</span></td>
              <td>{{ val.description }}</td>
              <td v-if="val.status == 'waiting'" style="
                  font-weight: bold;
                  background-color: yellow;
                  color: black;
                ">
                {{ $t(val.status) }}
              </td>
              <td v-if="val.status == 'deported'" style="font-weight: bold; background-color: green; color: white">
                {{ $t(val.status) }}
              </td>
              <td>
                <button @click="del(val.recid)" v-if="hasPermission('delete_receipt_project')">
                  <i class="bi bi-trash3-fill"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  components: {},
  data() {
    return {
      name: "",
      status: "",
      mobile: "",
      code: "",
      data: [],
      recnum: null,
      paydate: null,
      realty: null,
      renter: null,
      contract: null,
    };
  },
  async mounted() {
    const result = await axios.get("/projects/allprojectreceipts");
    if (result.status == 200) {
      this.data = result.data.data;
      this.data2 = result.data.data;
    }
  },
  methods: {
    view(row) {
      this.$root.$router.push({
        path: `/AdminDashboard/JournalEntry/JournalEntryReview/${row.jid}`,
      });
    },
    async del(id) {
      Swal.fire({
        title: this.$t("confirm") + "-" + this.$t("delete"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const action = await axios.delete(`/projects/delpaymentextract/${id}`);
          if (action.status == 200) {
            Swal.fire("تم مسح السند");
            this.$router.go();
          }
        }
      });
    },
    async search() {
      const action = await axios.post(`/reports/allpayments`, {
        id: this.recnum,
        paydate: this.paydate,
        realty: this.realty,
        rentername: this.renter,
        conid: this.contract,
      });
      if (action.status == 200) {
        this.data = action.data.data;
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.namear.includes(this.name.toLowerCase())
      );
    },

    status() {
      this.table.rows = this.table.data.filter((x) => x.review == this.status);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 90%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

button {
  margin: 0.5%;
  padding: 5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.table {
  font-size: x-small;
}
</style>
