import router from "@/router/index";
import { createStore } from "vuex";
const CryptoJS = require("crypto-js");

const state = {
  loading: false,
  data: "",
  randp: "",
  name: "",
  id: "",
  typeofuser: "",
  branchno: "",
  branchname: "",
  username: "",
  fullname: "",
  firstlogin: "",
  login: "",
  token: "",
  cnum: "",
  dep: "",
  lang: "",
  userPermissions: [],
  // surl: "http://demo.hesabtech.com",
  // imgurl: "http://demo-backend.hesabtech.com/upload",
  surl: "http://hesabtech.com",
  imgurl: "http://92.205.190.86:3000/upload",
  // surl: "http://localhost:8080",
  // imgurl: "http://localhost:3000/upload",
};
const getters = {};
const mutations = {
  redirectTo(state, payload) {
    router.push({ name: payload });
  },

  start() {
    const passphrase = process.env.VUE_APP_SOCKET_ENDPOINT;
    const decrept = CryptoJS.AES.decrypt(this.state.data, passphrase);
    const data2 = JSON.parse(decrept.toString(CryptoJS.enc.Utf8));
    this.state.name = data2[0].name;
    this.state.id = data2[0].id;
    this.state.typeofuser = data2[0].typeofuser;
    this.state.branchno = data2[0].branchno;
    this.state.branchname = data2[0].branchname;
    this.state.username = data2[0].username;
    this.state.fullname = data2[0].fullname;
    this.state.dep = data2[0].dep;
    this.state.lang = data2[0].lang;
    this.state.firstlogin = data2[0].firstlogin;
    this.state.login = data2[0].login;
    this.state.randp = data2[0].randp;
    this.state.userPermissions = data2[0].permissions;

    this.state.cnum = [JSON.parse(data2[0].cnum)];
  },
};
const actions = {
  redirectTo({ commit }, payload) {
    commit("redirectTo", payload.val);
  },

};
export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {},
});
