<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#" class="active">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main">
      <h4>{{ $t("management") }} {{ $t("projects") }}</h4>
      <div class="body">
        <div class="part">
          <h6>{{ $t("projects") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/allprojects">
                {{ $t("projects") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/createproject">{{ $t("add") }}
                {{ $t("project") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("products") }} / {{ $t("items") }} / {{ $t("classification") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/purchases/AllProducts">
                {{ $t("products") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/purchases/AllProducts/ProductCategories">{{ $t("items") }}
              </router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/AdminDashboard/AllTasks">{{ $t("classification") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("purchases") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/allprojectsbills">{{ $t("purchases") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/CreateProjectsBill">{{ $t("add") }} {{ $t("invoice") }} {{
                $t("purchases") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("suppliers") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/purchases/suppliers">{{ $t("suppliers") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/AdminDashboard/Suppliers/NewSupplier">{{ $t("add") }} {{ $t("supplier") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/SuplierStatementprojects">
                {{ $t("Account Statement") }} {{ $t("suppliers") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/AllSupplierReceiptsproject">{{ $t("receipts") }} {{ $t("suppliers")
                }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("contractors") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/contractors">{{ $t("contractors") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/contractors/allcontractorscontracts">{{ $t("contracts") }} {{
                $t("contractors")
              }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/AllExtracts">{{ $t("extracts")
                }}</router-link>
            </li>

            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/AllExtractsPayment">{{ $t("receipts") }} {{ $t("paid")
                }} {{ $t("extracts") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/ContractorStatementprojects">
                {{ $t("Account Statement") }} {{ $t("contractors") }}</router-link>
            </li>
          </ul>
        </div>
        <div class="part">
          <h6>{{ $t("petty") }} {{ $t("projects") }}</h6>
          <ul>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/AllReceivePatty">{{ $t("receive") }} {{ $t("petty") }}</router-link>
            </li>
            <li>
              <i class="bi bi-three-dots-vertical"></i>
              <router-link to="/ProjectsManagement/AllReceivePatty/pattystatement">{{ $t("Account Statement") }} {{
                $t("petty")
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="part">
        <h6>{{ $t("reports") }} {{ $t("projects") }}</h6>
        <ul>
          <li>
            <i class="bi bi-three-dots-vertical"></i>
            <router-link to="/ProjectsManagement/CostReport">{{ $t("cost") }}</router-link>
          </li>
          <li>
            <i class="bi bi-three-dots-vertical"></i>
            <router-link to="/ProjectsManagement/CostReportDetailsClass">التكلفة بالتبويب</router-link>
          </li>
          <li>
            <i class="bi bi-three-dots-vertical"></i>
            <router-link to="/ProjectsManagement/CostReportitems">{{ $t("details") }} {{ $t("cost") }}</router-link>
          </li>

          <li>
            <i class="bi bi-three-dots-vertical"></i>
            <router-link to="/ProjectsManagement/taxreportowners">{{ $t("tax") }} {{ $t("purchases") }} {{ $t("owners")
              }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
// import store from "@/store";
// import axios from "axios";
// import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  props: ["css"],
  components: {},
  data() {
    return {
      costomer: 0,
      suppliers: 0,
      invoices: 0,
      bills: 0,
      salles: "",
    };
  },
  async mounted() { },
  async created() { },
  methods: {
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  display: grid;
  grid-template-columns: repeat(3, 5fr);
  grid-gap: 20px;
  padding: 1%;
  // min-height: 500px;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

#content main .box-info {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 24px;
  margin-top: 10px;
}

#content main .box-info li {
  width: 25%;
  padding: 2%;
  background: #f9f9f9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
}

#content main .box-info li .bx {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content main .box-info li .bx {
  background: v-bind("css.color");
  color: black;
}

#content main .box-info li .text h5 {
  font-size: 20px;
  font-weight: 600;
  color: #342e37;
}

#content main .box-info li .text p {
  color: #342e37;
  font-size: 14px;
}

@media screen and (max-width: 576px) {
  .body {
    display: grid;
    grid-template-columns: repeat(1, 5fr);
    grid-gap: 20px;
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .part {
    font-size: small;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
  }

  .bi {
    margin: 1%;
  }
}
</style>
