<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <a class="active" href="#">{{ $t("all") }} {{ $t("extracts") }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>{{ $t("extracts") }}</h3>
                    <input type="text" :placeholder="$t('name')" v-model="name" />
                    <input type="text" :placeholder="$t('code')" v-model="code" />
                    <input type="text" :placeholder="$t('contractor')" v-model="contractor" />
                    <input type="text" :placeholder="$t('code') + $t('contract')" v-model="codecontract" />
                    <input type="text" :placeholder="$t('project')" v-model="codeproject" />
                    <select name="" id="" v-model="status">
                        <option value="all">{{ $t("all") }}</option>
                        <option value="waiting">{{ $t("waiting") }}</option>
                        <option value="confirmed">{{ $t("confirmed") }}</option>
                        <option value="deleted">{{ $t("deleted") }}</option>
                    </select>
                </div>
                <div class="head2">
                    <button>
                        <router-link to="/ProjectsManagement/AllExtracts/CreateExtract" class="btn-download">
                            <span class="text">
                                <i class="bi bi-clipboard-plus-fill"></i>
                                {{ $t("add") }} {{ $t("extract") }}</span>
                        </router-link>
                    </button>
                </div>
            </div>
            <div class="body">
                <div>
                    <table-lite :is-static-mode="true" :columns="table.columns" :rows="table.rows"
                        :total="table.totalRecordCount" :sortable="table.sortable" @row-delete="rowDelete"
                        @row-edite="eslam" @row-view="InvoiceView"></table-lite>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
// import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
    title: "Bills",
    props: ["css"],
    components: { TableLite },
    data() {
        return {
            name: "",
            email: "",
            mobile: "",
            code: "",
            contractor: "",
            codecontract: "",
            codeproject: "",
            status: ""
        };
    },
    async mounted() {

    },
    setup() {
        // Table config
        const table = reactive({
            columns: [
                {
                    label: "code",
                    field: "cid",
                    width: "1%",
                    text: "EXT-",
                    sortable: true,
                    isKey: true,
                    headerClasses: ["bg-gold", "color-red"],
                    columnClasses: ["bg-gray"],
                },
                {
                    label: "name",
                    field: "name",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "project",
                    field: "project",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "contractor",
                    field: "contractorname",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "amount",
                    field: "amount",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "date",
                    field: "date",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "time",
                    field: "time",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "status",
                    field: "status",
                    width: "10%",
                    sortable: true,
                },
                {
                    label: "option",
                    // headerClasses: ["bg-gold"],
                    // columnClasses: ["bg-gray"],
                    // columnStyles: { background: "gray" },
                    field: "quick",
                    width: ".5%",
                    display: function () {
                        return `<button class="btn btn-light"><i class="bi bi-search"></i></button>`;
                    },
                },
            ],
            rows: [],
            data: [],
            totalRecordCount: computed(() => {
                return table.rows.length;
            }),
            sortable: {},
        });
        const rowDelete = async (row) => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const del = await axios.delete(`/projects/delextractcontract/${row.cid}`);
                    if (del.status == 200) {
                        const indexOfObject = table.rows.findIndex((object) => {
                            return object.id === row.id;
                        });
                        table.rows.splice(indexOfObject, 1);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }
                }
            });
        };
        const rowEdit = () => {
            // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
        };
        const rowView = (row) => {
            console.log("Row rowView!", row);
        };
        /**
         * Table search event
         */
        const doSearch = (offset, limit, order, sort) => {
            table.isLoading = true;

            // Start use axios to get data from Server
            let url = "/projects/allextracts";
            axios.get(url).then((response) => {
                table.rows = response.data.data;
                table.data = response.data.data;
                table.totalRecordCount = response.data.data.length;
                table.sortable.order = order;
                table.sortable.sort = sort;
            });
        };

        // Get data first
        doSearch(0, 10, "id", "asc");
        return {
            table,
            rowDelete,
            rowEdit,
            rowView,
        };
    },
    methods: {
        InvoiceView(row) {
            this.$root.$router.push({
                path: `/ProjectsManagement/AllExtracts/extractview/${row.cid}`,
                params: { id: row.id },
            });
        },
        ...mapActions(["redirectTo"]),
    },
    watch: {
        name() {
            this.table.rows = this.table.data.filter((x) =>
                x.name.includes(this.name.toLowerCase())
            );
        },
        code() {
            this.table.rows = this.table.data.filter((x) => x.cid == this.code);
            if (this.code == "") {
                this.table.rows = this.table.data;
            }
        },
        contractor() {
            this.table.rows = this.table.data.filter((x) =>
                x.contractorname.includes(this.contractor.toLowerCase())
            );
        },
        codecontract() {
            this.table.rows = this.table.data.filter((x) => x.contractid == this.codecontract);
            if (this.codecontract == "") {
                this.table.rows = this.table.data;
            }
        },
        codeproject() {
            this.table.rows = this.table.data.filter((x) =>
                x.project.includes(this.codeproject.toLowerCase())
            );

        },
        status() {
            this.table.rows = this.table.data.filter((x) => x.status == this.status);
            if (this.status == "all") {
                this.table.rows = this.table.data;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    // border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    display: flex;
    // border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
    width: 50%;
    //   border: black 1px solid;
}

.part img {
    width: 100%;
    height: 100px;
    //   border: black 1px solid;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 60%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 40%;
    text-align: center;
}

.head2 button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
    width: 30%;
}

.head2 button a {
    color: white;
}
</style>