<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("projects") }} & {{ $t("classification") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("classification") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("classification") }}</h3>
      </div>
      <div class="head">
        <div class="head1">
          <input type="text" :placeholder="$t('name')" v-model="name" />
          <input type="text" :placeholder="$t('code')" v-model="code" />
          <input type="text" :placeholder="$t('status')" v-model="status" />
        </div>
        <div class="head2">
          <button>
            <router-link to="/AdminDashboard/AllTasks/NewTask">+ {{ $t("add") }} {{ $t("classification")
              }}</router-link>
          </button>
        </div>
      </div>
      <div class="body">
        <!-- <div class="row">
          <div class="col-sm-2">
            <input type="text" class="form-control" :placeholder="$t('name')" v-model="name" />
          </div>
          <div class="col-sm-2">
            <input type="text" class="form-control" :placeholder="$t('code')" v-model="code" />
          </div>
          <div class="col-sm-2">
            <input type="text" class="form-control" :placeholder="$t('status')" v-model="status" />
          </div>
          <div class="col">
            <button class="btn btn-primary">
              {{ $t("search") }} <i class="bi bi-search"></i>
            </button>
            &nbsp;
            <button class="btn btn-light">
              {{ $t("reset") }} <i class="bi bi-arrow-counterclockwise"></i>
            </button>
          </div>
        </div> -->
        <div>
          <table-lite :is-static-mode="true" :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount"
            :sortable="table.sortable" @row-delete="rowDelete" @row-edite="eslam" @row-view="rowView"></table-lite>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed } from "vue";
import TableLite from "../../components/TableLite.vue";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  props: ["css"],
  components: { TableLite },
  data() {
    return {
      name: "",
      email: "",
      mobile: "",
      code: "",
      status: "",
      r2: store.state.randp.r2,
    };
  },
  async mounted() {
    if (this.r3 == false) {
      this.redirectTo({ val: "Home" });
    }
  },
  setup() {
    // Table config
    const table = reactive({
      columns: [
        {
          label: "code",
          field: "tid",
          width: "1%",
          sortable: true,
          isKey: true,
          headerClasses: ["bg-gold", "color-red"],
          columnClasses: ["bg-gray"],
        },
        {
          label: "project",
          field: "name",
          width: "15%",
          sortable: true,
        },
        {
          label: "description",
          field: "description",
          width: "20%",
          sortable: true,
        },
        {
          label: "status",
          field: "status",
          width: "10%",
          sortable: true,
        },
        {
          label: "option",
          // headerClasses: ["bg-gold"],
          // columnClasses: ["bg-gray"],
          // columnStyles: { background: "gray" },
          field: "quick",
          width: ".5%",
          display: function () {
            return `<button class="btn btn-light"><i class="bi bi-pencil-square"></i></button>`;
          },
        },
      ],
      rows: [],
      data: [],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
    });
    const rowDelete = async (row) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const del = await axios.delete(`/data/deltask/${row.tid}`);
          if (del.status == 200) {
            const indexOfObject = table.rows.findIndex((object) => {
              return object.id === row.id;
            });
            table.rows.splice(indexOfObject, 1);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        }
      });
    };
    const rowEdit = () => {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
    };
    const rowView = (row) => {
      console.log("Row rowView!", row);
    };
    /**
     * Table search event
     */
    const doSearch = (offset, limit, order, sort) => {
      table.isLoading = true;

      // Start use axios to get data from Server
      let url = "/data/alltasks";
      axios.get(url).then((response) => {
        table.rows = response.data.data;
        table.data = response.data.data;
        table.totalRecordCount = response.data.data.length;
        table.sortable.order = order;
        table.sortable.sort = sort;
      });
    };

    // Get data first
    doSearch(0, 10, "id", "asc");
    return {
      table,
      rowDelete,
      rowEdit,
      rowView,
    };
  },
  methods: {
    eslam(row) {
      // this.$router.push(`/AdminDashboard/Customers/editecustomer/${row.id}`);
      this.$root.$router.push({
        path: `/AdminDashboard/Suppliers/editesupplier/${row.id}`,
        params: { id: row.id },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    name() {
      this.table.rows = this.table.data.filter((x) =>
        x.name.includes(this.name.toLowerCase())
      );
    },
    status() {
      this.table.rows = this.table.data.filter((x) =>
        x.status.includes(this.status)
      );
    },
    code() {
      this.table.rows = this.table.data.filter((x) => x.id == this.code);
      if (this.code == "") {
        this.table.rows = this.table.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
