<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("companies") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">
              {{ $t("add") }} {{ $t("companies") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="head-option">
      <ul>
        <!-- <li>
                    <a href="#" class="btn-download">
                      <span class="text">Download PDF</span>
                    </a>
                  </li> -->
        <li>
          <router-link to="/AdminDashboard/CompanyContracts" class="btn-download">
            <span class="text">
              <i class="bi bi-arrow-left-square-fill"></i> Back</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("companies") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("name") }}
              <span class="error-feedback" v-if="v$.fullname.$error">{{
                "*"
              }}</span></span>
            <input type="text" class="form-control" v-model="fullname" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("number") }} {{ $t("contract")
              }}<span class="error-feedback" v-if="v$.contractno.$error">{{
                "*"
              }}</span></span>
            <input type="text" class="form-control" v-model="contractno" />
          </div>
          <div class="input-group">
            <span class="input-group-text">
              {{ $t("type") }} {{ $t("contract") }}
            </span>
            <select type="text" class="form-control" v-model="typeofcontract"></select>
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("number") }} {{ $t("users") }}</span>
            <input type="number" class="form-control" v-model="usersno" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("branches") }}</span>
            <input type="number" class="form-control" v-model="branchesno" />
          </div>
        </div>
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("date") }} {{ $t("expiry") }}
              <span class="error-feedback" v-if="v$.expdate.$error">{{
                "*"
              }}</span></span>
            <input type="date" class="form-control" v-model="expdate" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("number") }} {{ $t("tax") }}
            </span>
            <input type="number" class="form-control" v-model="tax" />
          </div>
          <div class="input-group">
            <span class="input-group-text">{{ $t("address") }}
            </span>
            <input type="text" class="form-control" v-model="address" />
          </div>
        </div>
        <div class="part1">
          <div class="input-group">
            <span class="input-group-text">{{ $t("image") }}</span>
            <input type="file" class="form-control form-control-sm" id="image" :name="this.sn" @change="onFileChange" />
          </div>
          <div id="preview">
            <img v-if="url" :src="url" />
          </div>
        </div>
      </div>
      <div class="buttom">
        <button class="buttonconfirm" type="submit" @click="Edite">
          {{ $t("add") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  async mounted() {
    const result1 = await axios.get("/data/allbranches");
    if (result1.status == 200) {
      this.branches = result1.data.data;
    }
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      data: "",
      branches: [],
      branch: "",
      branchname: "",
      branchno: "",
      fullname: "",
      contractno: "",
      typeofcontract: "",
      usersno: "",
      residence: "",
      expdate: "",
      nationalid: "",
      url: "",
      branchesno: 1,
      tax: "",
      address: ""
    };
  },
  validations() {
    return {
      fullname: { required },
      contractno: { required },
      usersno: { required },
      expdate: { required },
    };
  },
  methods: {
    async Edite() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post(`/admin/addcompanycontract`, {
          username: store.state.randp.username,
          cname: this.fullname,
          cnum: this.contractno,
          typeofcontract: this.typeofcontract,
          usersno: this.usersno,
          expdate: this.expdate,
          branchesno: this.branchesno,
          tax: this.tax,
          address: this.address
        });
        if (result.status == 200) {
          let formData = new FormData();
          formData.append("file", this.file);
          axios.patch(`/clogo/${this.contractno}`, formData);
          this.redirectTo({ val: "CompanyContracts" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {
    branch() {
      this.branchname = this.branch.namear;
      this.branchno = this.branch.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 1%;
}

a {
  text-decoration: none;
}

.head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #342e37;
}

.head-title .left .breadcrumb {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.head-title .left .breadcrumb li {
  color: #342e37;
}

.head-title .left .breadcrumb li a {
  color: #aaaaaa;
  pointer-events: none;
}

.head-title .left .breadcrumb li a.active {
  color: v-bind("css.color");
  pointer-events: unset;
}

.head-option {
  direction: v-bind("css.AR");
  width: 100%;
  // border: #342e37 1px solid;
  padding: 0.5%;
}

.head-option ul li {
  display: inline;
  padding: 0.5%;
}

.head-option .btn-download {
  width: 20%;
  padding: 0.5%;
  border-radius: 5px;
  background: v-bind("css.color");
  color: #f9f9f9;
}

.main .head {
  border-top: v-bind("css.color") 5px solid;
  background: v-bind("css.color");
  color: #f9f9f9;
  border-radius: 3px 3px 0px 0px;
  padding: 0.25%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.main .body {
  background: v-bind("css.light");
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
  display: flex;
}

.main .body .part1 {
  width: 33%;
  margin-right: 1%;
  margin-left: 1%;
  background: v-bind("css.light");
}

.main .body .part1 .input-group {
  margin: 1%;
}

.main .body .part1 .input-group span {
  width: 25%;
  font-size: small;
}

.main .body .part2 {
  width: 25%;
  background: v-bind("css.light");
  padding-right: 2%;
  padding-left: 2%;
}

.main .body .part2 .part2head {
  background: rgba(191, 214, 219, 0.534);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.main .body .part2 .part2body {
  box-shadow: rgba(75, 68, 68, 0.774) 0.25px 0.25px 1px;
  padding: 3%;
}

input:disabled {
  background-color: white;
}

.main .body .part3 {
  width: 18%;
  //   border: black 1px solid;
  padding: 1%;
  background-color: #ebe9e9;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  border-radius: 10px;
}

.buttom {
  margin: 1%;
  text-align: center;
}

.table {
  background-color: white;
}

img {
  // border: black 1px solid;
  padding: 0.5%;
  width: 200px;
  height: 300px;
  border-radius: 20px;
  margin-right: 25%;
}

@media screen and (max-width: 576px) {
  .main .body {
    background: v-bind("css.light");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
    display: block;
  }

  .main .body .part1 {
    width: 90%;
    margin-right: 1%;
    margin-left: 1%;
    background: v-bind("css.light");
  }
}
</style>
