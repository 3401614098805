<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/accounting/home" class="active">{{
            $t("accounting")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active" href="#"> {{ $t("Account Statement") }}</a>
        </li>
        <li>-</li>
        <li v-if="this.view != 0">
          <a class="active" href=""><span v-if="css.lang == 'ar'">{{ namear }}</span><span v-if="css.lang == 'en'">{{
            nameen }}</span> {{ view }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("Account") }}</span>
        <div style="width: 30%; padding: 1%">
          <v-select :options="accounts" label="namear" v-model="item" style="background-color: white; color: black">
            <template #option="option">
              <span style="color: black">
                {{ option.namear }} - {{ option.actnum }}</span>
            </template>
          </v-select>
        </div>

        <span>{{ $t("from") }}</span>
        <input type="date" :placeholder="$t('from')" v-model="from" />
        <span>{{ $t("to") }}</span>
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <span>{{ $t("ccenter") }}</span>
        <select v-model="parent">
          <option v-for="x in level1" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <select v-model="parent2" v-if="level2.length > 0">
          <option v-for="x in level2" :key="x" :value="x.actnum">
            {{ x.namear }}
          </option>
        </select>
        <button type="submit" @click="submit">
          <i class="bi bi-search"></i> {{ $t("search") }}
        </button>
      </div>
      <div class="head2">
        <button>
          <router-link to="/accounting/JournalEntry/CreateJournalEntry">+ {{ $t("add") }} {{ $t("JL") }}</router-link>
        </button>
        <button type="submit" @click="pdf" v-if="this.data1.length > 0">
          <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
        </button>
        <button type="submit" @click="exportToExcel" v-if="this.data1.length > 0">
          <i class="bi bi-file-earmark-excel"></i> Excel
        </button>
      </div>
    </div>
    <br />
    <div class="body" v-if="this.view == 0">برجاء اختيار الحساب</div>
    <div class="body" v-if="this.view != 0" id="main">
      <h3>
        {{ $t("transactions") }} {{ $t("Account") }} -
        <span v-if="css.lang == 'ar'">{{ namear }}</span><span v-if="css.lang == 'en'">{{ nameen }}</span> {{ view }}
      </h3>
      <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("code") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("debit") }}</th>
            <th>{{ $t("credit") }}</th>
            <th>{{ $t("balance") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in data1" :key="i">
            <td>
              <router-link to="">{{ i.journalid }}</router-link>
            </td>
            <td>{{ i.date }}</td>
            <td>{{ i.time }}</td>
            <td>{{ i.description }}</td>
            <td>
              {{ Number(i.dpt).toFixed(2) }}
            </td>
            <td>
              {{ Number(i.crd).toFixed(2) }}
            </td>
            <td>{{ Number(i.balance).toFixed(2) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">{{ $t("total") }}</td>
            <td>{{ Number(totaldpt).toFixed(2) }}</td>
            <td>{{ Number(totalcrd).toFixed(2) }}</td>
            <td></td>
          </tr>
          <tr v-if="this.typeact == 'debit'">
            <td colspan="4">{{ $t("balance") }}</td>
            <td>{{ Number(totaldpt - totalcrd).toFixed(2) }}</td>
          </tr>
          <tr v-if="this.typeact == 'credit'">
            <td colspan="5">{{ $t("balance") }}</td>
            <td>{{ Number(totalcrd - totaldpt).toFixed(2) }}</td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td>{{ $t("balance") }} {{ $t("final") }}</td>
            <td>{{ totalbalance }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
export default {
  components: {
    vSelect,
  },
  async mounted() {
    this.view = this.$route.params.id;
    this.namear = this.$route.params.namear;
    this.nameen = this.$route.params.nameen;
    this.fromdate = this.$route.params.start;
    this.todate = this.$route.params.end;
    this.typeact = this.$route.params.typeact;
    const result3 = await axios.get("/data/getcostcenter");
    const result2 = await axios.get("/data/getaccounttrue");
    if (result2.status == 200) {
      this.accounts = result2.data.data;
      this.branches = result3.data.data;
      this.level1 = this.branches.filter((x) => x.level == 1);
    }
    if (this.view != 0) {
      const result = await axios.get(
        `/reports/generalledgersummarydaily/${this.fromdate}/${this.todate}/${this.view}/${this.typeact}`
      );
      if (result.status == 200) {
        const data = result.data.data;
        const balance = result.data.data2;
        this.totaldpt = data.reduce((acc, array) => acc + Number(array.dpt), 0);
        this.totalcrd = data.reduce((acc, array) => acc + Number(array.crd), 0);
        this.data1 = [];
        if (this.typeact == "debit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].dpt - balance[0].crd;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            dpt: 0,
            crd: 0,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) +
              Number(data[i].dpt) -
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              time: data[i].time,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        if (this.typeact == "credit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].crd - balance[0].dpt;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) -
              Number(data[i].dpt) +
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        this.totaldpt = data.reduce((acc, array) => acc + Number(array.dpt), 0);
        this.totalcrd = data.reduce((acc, array) => acc + Number(array.crd), 0);
      }
    }
  },
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      data1: [],
      view: "",
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [],
      selection: false,
      filter: "",
      totalbalance: 0,
      totaldpt: 0,
      totalcrd: 0,
      from: "",
      to: "",
      parent: "",
      parent2: "",
      level1: [],
      level2: [],
      branches: [],
    };
  },
  methods: {
    async submit() {
      this.parent = "";
      this.parent2 = "";
      this.level2 = [];
      const result = await axios.get(
        `/reports/generalledgersummarydaily/${this.from}/${this.to}/${this.item.actnum}/${this.item.typeact}`
      );
      if (result.status == 200) {
        this.view = this.item.actnum;
        this.fromdate = this.from;
        this.todate = this.to;
        this.namear = this.item.namear;
        this.nameen = this.item.nameen;
        this.typeact = this.item.typeact;
        const data = result.data.data;
        const balance = result.data.data2;
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
        this.data1 = [];
        if (this.typeact == "debit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].dpt - balance[0].crd;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            dpt: balance[0].dpt,
            crd: balance[0].crd,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) +
              Number(data[i].dpt) -
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              time: data[i].time,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        if (this.typeact == "credit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].crd - balance[0].dpt;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            dpt: balance[0].dpt,
            crd: balance[0].crd,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) -
              Number(data[i].dpt) +
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: "JournalReport.pdf",
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      });
    },
    exportToExcel() {
      var data = [];
      for (let i = 0; i < this.data1.length; i++) {
        const name = this.data1[i];
        data.push({
          رقم_القيد: name.journalid,
          رقم_الحساب: this.item.actnum,
          اسم_الحساب: this.item.namear,
          الوصف: name.description,
          المدين: name.dpt,
          الدائن: name.crd,
          الرصيد: name.balance,
          اليوم: name.date,
          الساعة: name.time,
        });
      }
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Convert data to a worksheet
      const ws = XLSX.utils.json_to_sheet(data);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate a download link to the Excel file
      XLSX.writeFile(wb, "JournalEntry.xlsx");
    },
  },
  watch: {
    async parent() {
      this.level2 = this.branches.filter(
        (x) => x.actnum.startsWith(this.parent) && x.actnum != this.parent
      );
      const result = await axios.get(
        `/reports/generalledgersummarydailycenter/${this.from}/${this.to}/${this.item.actnum}/${this.item.typeact}/${this.item.typeact}/${this.parent}`
      );
      if (result.status == 200) {
        this.view = this.item.actnum;
        this.fromdate = this.from;
        this.todate = this.to;
        this.namear = this.item.namear;
        this.nameen = this.item.nameen;
        this.typeact = this.item.typeact;
        const data = result.data.data;
        const balance = result.data.data2;
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
        this.data1 = [];
        if (this.typeact == "debit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].dpt - balance[0].crd;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            dpt: balance[0].dpt,
            crd: balance[0].crd,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) +
              Number(data[i].dpt) -
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              time: data[i].time,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        if (this.typeact == "credit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].crd - balance[0].dpt;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            dpt: balance[0].dpt,
            crd: balance[0].crd,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) -
              Number(data[i].dpt) +
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
      }
    },
    async parent2() {
      const result = await axios.get(
        `/reports/generalledgersummarydailycenter/${this.from}/${this.to}/${this.item.actnum}/${this.item.typeact}/${this.parent2}`
      );
      if (result.status == 200) {
        this.view = this.item.actnum;
        this.fromdate = this.from;
        this.todate = this.to;
        this.namear = this.item.namear;
        this.nameen = this.item.nameen;
        this.typeact = this.item.typeact;
        const data = result.data.data;
        const balance = result.data.data2;
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
        this.data1 = [];
        if (this.typeact == "debit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].dpt - balance[0].crd;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) +
              Number(data[i].dpt) -
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              time: data[i].time,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        if (this.typeact == "credit") {
          this.totalbalance = 0;
          this.totalbalance = balance[0].crd - balance[0].dpt;
          this.data1.push({
            description: "open balance",
            date: this.fromdate,
            balance: this.totalbalance,
          });
          for (let i = 0; i < this.data1.length; i++) {
            this.totalbalance = Number(
              Number(this.totalbalance) -
              Number(data[i].dpt) +
              Number(data[i].crd)
            ).toFixed(2);
            this.data1.push({
              journalid: data[i].journalid,
              description: data[i].description,
              date: data[i].trandate,
              dpt: Number(data[i].dpt),
              crd: Number(data[i].crd),
              balance: Number(this.totalbalance),
            });
          }
        }
        this.totaldpt = Number(
          data.reduce((acc, array) => acc + Number(array.dpt), 0)
        ).toFixed(2);
        this.totalcrd = Number(
          data.reduce((acc, array) => acc + Number(array.crd), 0)
        ).toFixed(2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
