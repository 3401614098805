<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("sales") }}</a>
          </li>
          <li>-</li>
          <li>
            <a href="#">{{ $t("suppliers") }}</a>
          </li>
          <li>-</li>
          <li>
            <a class="active" href="#">{{ $t("add") }} {{ $t("supplier") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <h3>{{ $t("add") }} {{ $t("supplier") }}</h3>
      </div>
      <div class="body">
        <div class="part1">
          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("supplier") }}
              <span class="error-feedback" v-if="v$.name.$error">{{
                "*"
              }}</span></label>
            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="name" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("name") }} {{ $t("company") }}
              <span class="error-feedback" v-if="v$.companyname.$error">{{
                "*"
              }}</span></label>

            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="companyname" />
            </div>
          </div>

          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("mobile") }}
            </label>
            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="mobile" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("email") }}
            </label>

            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="email" />
            </div>
          </div>

          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("website") }}
            </label>
            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="website" />
            </div>
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm"> {{ $t("address") }}
            </label>
            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="address" />
            </div>
          </div>


          <div class="row mb-2">
            <label for="inputEmail3" class="col-sm-2 col-form-label-sm"> {{ $t("number") }} {{ $t("tax") }}
            </label>
            <div class="col-sm-4">
              <input type="text" class="form-control form-control-sm" id="inputEmail3" v-model="taxnumber" />
            </div>
          </div>
        </div>

        <div style="clear: both; margin: 5%; text-align: center">
          <button class="buttonconfirm" :disabled="disabled" @click.prevent="confirm">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
  async mounted() {
    const data2 = await axios.get(`/data/delsupplier/${this.$route.params.id}`);
    if (data2.status == 200) {
      const data = data2.data.data[0];
      this.name = data.name;
      this.email = data.email;
      this.mobile = data.mobile;
      this.companyname = data.companyname;
      this.website = data.website;
      this.taxnumber = data.taxnumber;
      this.street = data.street;
      this.city = data.city;
      this.area = data.area;
      this.zipcode = data.zipcode;
      this.building = data.building;
      this.country = data.country;
      this.streetsh = data.streetsh;
      this.citysh = data.citysh;
      this.areash = data.areash;
      this.zipcodesh = data.zipcodesh;
      this.buildingsh = data.buildingsh;
      this.countrysh = data.countrysh;
      this.categorie = data.categorie;
    }
    const result = await axios.get(
      `/data/suppliertrans/${this.$route.params.id}`
    );

    if (result.status == 200) {
      this.trans = result.data.data;
      const dpt = this.trans.reduce((acc, array) => acc + Number(array.dpt), 0);
      const crd = this.trans.reduce((acc, array) => acc + Number(array.crd), 0);
      this.totalbalance = crd - dpt;
      this.invoice = this.trans.filter((x) => x.journalid.includes("BILL"));
      const invoicedpt = this.invoice.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      const invoicecrd = this.invoice.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
      this.totalinvoice = invoicecrd - invoicedpt;
      this.payment = this.trans.filter((x) => x.journalid.includes("PYT"));
      const paymentdpt = this.payment.reduce(
        (acc, array) => acc + Number(array.dpt),
        0
      );
      const paymentcrd = this.payment.reduce(
        (acc, array) => acc + Number(array.crd),
        0
      );
      this.totalreceipt = paymentdpt - paymentcrd;
    }
  },
  props: ["css"],
  data() {
    return {
      v$: useValidate(),
      name: "",
      email: "",
      mobile: "",
      companyname: "",
      website: "",
      taxnumber: "",
      street: "",
      city: "",
      area: "",
      zipcode: "",
      building: "",
      country: "",
      streetsh: "",
      citysh: "",
      areash: "",
      zipcodesh: "",
      buildingsh: "",
      countrysh: "",
      copy: false,
      trans: [],
      categorie: "",
      totalbalance: 0,
      invoice: [],
      totalinvoice: 0,
      payment: [],
      totalreceipt: 0,
      isActive: true,
      isActive2: false,
    };
  },
  validations() {
    return {
      name: { required },
      companyname: { required },
    };
  },
  methods: {
    async confirm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const result = await axios.post(
          `/data/editesupplier/${this.$route.params.id}`,
          {
            username: store.state.randp.username,
            name: this.name,
            email: this.email,
            mobile: this.mobile,
            companyname: this.companyname,
            website: this.website,
            taxnumber: this.taxnumber,
            street: this.street,
            city: this.city,
            area: this.area,
            zipcode: this.zipcode,
            building: this.building,
            country: this.country,
            streetsh: this.streetsh,
            citysh: this.citysh,
            areash: this.areash,
            zipcodesh: this.zipcodesh,
            buildingsh: this.buildingsh,
            countrysh: this.countrysh,
            categorie: this.categorie,
          }
        );
        if (result.status == 200) {
          this.redirectTo({ val: "suppliers" });
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Data Entry successfully",
          });
          // this.redirectTo({ val: "Home" });
        } else if (result.status == 401) {
          alert("Account Already Created Before");
        }
      }
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  // border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  display: flex;
  // border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.part .group {
  width: 50%;
  //   border: black 1px solid;
}

.part img {
  width: 100%;
  height: 100px;
  //   border: black 1px solid;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 30%;
}

.head2 button a {
  color: white;
}
</style>
