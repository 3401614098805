<template>
  <div class="page">
    <div class="head-title">
      <div class="left">
        <ul class="breadcrumb">
          <li>
            <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("receipts") }} {{ $t("paid") }}</router-link>
          </li>
          <li>-</li>
          <li>
            <router-link to="" class="active">{{ $t("receipts") }}
              {{ $t("paid") }} {{ $t("contractor") }} - {{ this.$route.params.id }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main">
      <div class="head">
        <div class="head1">
          <h3>
            <i class="bi bi-credit-card"></i> {{ $t("receipts") }}
            {{ $t("paid") }} {{ $t("contractor") }}
          </h3>
        </div>
        <div class="head2">
          <button class="buttonconfirm" type="submit" :disabled="disabled" @click="confirm"
            v-if="alldata.status == 'waiting'">
            {{ $t("confirm") }}
          </button>
          <button class="buttonconfirm" type="submit" @click="pdf">
            <i class="bi bi-filetype-pdf"></i> {{ $t("PDF") }}
          </button>
        </div>
      </div>
      <div class="body">
        <div class="body1">
          <div class="group">
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contractor") }}
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="alldata.ownername">
              </div>
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("contracts") }}
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="alldata.proname">
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("total") }} {{ $t("amount") }} {{
                $t("extracts")
              }}
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="alldata.teamount">
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("total") }} {{ $t("amount") }} {{
                $t("receipts")
              }}
              </label>
              <div class="col-sm-4">
                <input type="text" disabled class="form-control form-control-sm" v-model="alldata.tramount">
              </div>
            </div>
            <div class="row mb-2">
              <label for="inputEmail3" class="col-sm-2 col-form-label-sm">رقم المستخلص
              </label>
              <div class="col-sm-4">
                <input type="text" class="form-control form-control-sm" v-model="alldata.extract">
              </div>
            </div>
          </div>

        </div>
        <br />
        <div class="body1">
          <div class="group" v-if="alldata.ptype == 'inhouse'">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("Method of Payment") }}
              </label>
              <div class="col-sm-7">
                {{ alldata.paytype }}
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("Account") }} {{ $t("payment") }}
              </label>
              <div class="col-sm-7">
              </div>
            </div>
          </div>
          <div class="group" v-if="alldata.ptype == 'other'">
            <div class="row mb-1">
              <label class="col-sm-5 col-form-label"> سداد عهدة مشروع </label>
              <div class="col-sm-2">
                <input disabled type="checkbox" v-model="alldata.ownerpayment">
              </div>
            </div>


          </div>
          <div class="group">
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("date") }} {{ $t("payment") }}
              </label>
              <div class="col-sm-6">
                <input type="date" disabled class="form-control" v-model="alldata.paydate" />
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-2 col-form-label">{{ $t("amount") }}
              </label>
              <div class="col-sm-6">
                <input type="text" disabled class="form-control" v-model="alldata.amount" />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="body1">
          <div class="group">
            <div class="row mb-2">
              <label class="col-sm-2 col-form-label-sm">
                <i class="bi bi-pin-angle-fill"></i> {{ $t("add") }}
                {{ $t("attachments") }}
              </label>
              <div class="col-sm-8">
                <div>
                  <div>
                    <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()" />
                  </div>
                  <br />
                  <!-- <div v-if="files.length > 0">
                    <div v-for="(file, key) in files" :key="file">
                      {{ file.name }} &nbsp;
                      <a href="#" class="remove-file" v-on:click="removeFile(key)"
                        >Remove</a
                      >
                      </div>
                    </div> -->
                  <table class="table" style="width: 100%">
                    <thead>
                      <tr>
                        <th>{{ $t("name") }}</th>
                        <th>{{ $t("cancel") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(file, key) in files" :key="file">
                        <td>{{ file.name }}</td>
                        <td>
                          <a href="#" class="remove-file" v-on:click="removeFile(key)"><i
                              class="bi bi-x-circle-fill"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="group">
          <h5>{{ $t("notes") }}</h5>
          <textarea disabled v-model="alldata.description" id="" style="width: 100%" rows="3"></textarea>
        </div>
      </div>
      <div id="main" style="display: none">
        <div style="direction: rtl;padding: 1%">
          <div style="padding-right: 45%"> <img src="../../assets/arinlogo.png" style="height: 100px; width: 25%;" />
          </div>
          <br />
          <div style="display: flex; text-align: center;font-size: x-large;font-weight: bold;">
            <div style="width: 35%;">سند صرف</div>
            <div style="width: 35%;">{{ this.$route.params.id }}</div>
            <div style="width: 35%;">Payment Voucher</div>
          </div>
          <br />
          <div style="display: flex; text-align: center;">
            <div style="width: 35%;"> التاريخ : {{ alldata.paydate }}</div>
            <div style="width: 35%;">المبلغ : {{ alldata.amount }} ر.س</div>
            <div style="width: 35%;"> </div>
          </div>
          <div style="display: flex; text-align: center;">
            <div style="width: 35%;"> رقم المستخلص : {{ alldata.extract }}</div>
          </div>

          <br />
          <div style="display: flex; text-align: center;">يستخدم نموذج الصرف بناء على البيانات المدونة
            أدناه وحسب البنود المتفق عليها :</div>
          <br />
          <div style="width: 100%;border: 3px solid black;font-size: large;padding: 1%;">
            <div style="display: flex;padding: 1%;">
              <div style="width: 50%;"> استلمت انا الموقع ادناه \ <span style="border-bottom: 1px solid black">{{
                alldata.ownername }}</span> </div>
              <div style="width: 50%;">إقامة رقم \ {{ contractor.staynumber }}</div>
            </div>
            <div style="display: flex;padding: 1%;">
              <div style="width: 50%;"> شركة و موسسة \ <span style="border-bottom: 1px solid black">{{
                contractor.companyname }}</span> </div>
              <div style="width: 50%;">سجل تجاري رقم \ </div>
            </div>
            <div style="display: flex;padding: 1%; ">
              <div style="width: 70%;"> طريقة السداد \ <span v-if="alldata.ownerpayment"
                  style="border-bottom: 1px solid black"> سداد من عهدة مشروع </span>
                <span v-if="alldata.ptype == 'inhouse'" style="border-bottom: 1px solid black">{{ alldata.paytype
                  }}</span>
              </div>
              <!-- <div style="width: 25%;">تاريخ \ </div>
              <div style="width: 25%;">حوالة بتاريخ \ </div>
              <div style="width: 25%;">م بنك \ </div> -->
            </div>
            <div style="display: flex;padding: 1%;">
              <div style="width: 50%;"> مبلغ و قدرة \ <span style="border-bottom: 1px solid black">{{ textamount }} ({{
                alldata.amount }} ريال)</span></div>
              <div style="width: 50%;">بتاريخ \ <span style="border-bottom: 1px solid black">{{ alldata.paydate
                  }}</span></div>
            </div>
            <div style="display: flex;padding: 1%;">
              <div style="width: 100%;"> وذلك مقابل \ {{ alldata.description }}</div>

            </div>


          </div>
          <br />
          وعلى ذلك جرى التوقيع
          <hr />

          <br />
          <div style="display: flex;">
            <div class="sign" style="width: 25%;text-align: center;">المستلم</div>
            <div class="sign" style="width: 25%;text-align: center;">المشرف</div>
            <div class="sign" style="width: 25%;text-align: center;">الحسابات</div>
            <div class="sign" style="width: 25%;text-align: center;">المدير العام</div>
            <br />
            <br />
            <br />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import html2pdf from "html2pdf.js";
var tafqeet = require("../../TafqeetJs-master/Tafqeet");
export default {
  props: ["css"],

  async mounted() {
    const result1 = await axios.get(`/projects/getreceiptproject/${this.$route.params.id}`);
    if (result1.status == 200) {
      this.alldata = result1.data.data[0];
    }
    const result2 = await axios.get(`/projects/getcontractordata/${this.alldata.ownerid}`);
    if (result2.status == 200) {
      this.contractor = result2.data.data[0];
    }
    this.url =
      store.state.imgurl + `/clogo/${this.$store.state.cnum[0].cnum}.png`;
    var fraction = this.alldata.amount.split(".");
    if (fraction.length == 2) {
      this.textamount =
        tafqeet.tafqeet(fraction[0]) +
        " " +
        "ريال" +
        " و " +
        tafqeet.tafqeet(fraction[1]) +
        " هلله " +
        " فقط ";
      if (Number(fraction[1]) == 0) {
        this.textamount =
          tafqeet.tafqeet(fraction[0]) + " " + "ريال" + " " + " فقط ";
      }
    }
    if (fraction.length == 1) {
      this.textamount =
        tafqeet.tafqeet(fraction[0]) +
        " " +
        "ريال" +
        " و " +
        tafqeet.tafqeet(fraction[1]) +
        " هلله " +
        " فقط ";
      this.textamount =
        tafqeet.tafqeet(fraction[0]) + " " + "ريال" + " " + "فقط";
    }
  },
  data() {
    return {
      alldata: [],
      url: "",
      textamount: "",
      contractor: ""
    };
  },
  methods: {
    async confirm() {
      this.disabled = true;
      const result = await axios.post(`/projects/confirmreceiptproject`, {
        username: store.state.randp.username,
        id: this.$route.params.id
      });
      if (result.status == 200) {
        this.redirectTo({ val: "AllExtractsPayment" });
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Data Entry successfully",
        });
        // this.redirectTo({ val: "Home" });
      } else if (result.status == 401) {
        alert("Account Already Created Before");
      }

    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.2,
        filename: `${this.$route.params.id} - سند صرف.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    ...mapActions(["redirectTo"]),
  },
  watch: {

  },

};
</script>
<style lang="scss" scoped>
.page {
  direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

.body {
  padding: 1%;
  // min-height: 500px;
  //   border: 1px solid black;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  border: 1px solid black;
  display: flex;
  border-radius: 5px;
}

.body2 {
  width: 100%;
  min-height: 100px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
}

.body3 {
  width: 100%;
  min-height: 100px;
}

.body4 {
  padding: 1%;
  width: 100%;
  min-height: 100px;
  background-color: #eee;
}

.part {
  font-size: large;
  width: 99%;
  padding: 2%;
  margin: 0.5%;
  background-color: white;
  border-radius: 5px;
  color: black;
  border: 1px solid black;
  font-size: small;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
  text-decoration: none;
  list-style-type: none;
  color: black;
}

.bi {
  margin: 1%;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 60%;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 40%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
  width: 10%;
}

.head2 button a {
  color: white;
}

.group {
  width: 100%;
}

.group2 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group3 {
  width: 100%;
  background-color: #eee;
  display: flex;
}

.group input {
  width: 80%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group2 input {
  width: 100%;
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

.group3 input {
  width: 50%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  text-align: center;
}

#preview {
  width: 50%;
}

#preview img {
  width: 100%;
  max-height: 200px;
}

.selection button {
  width: 10%;
  border: none;
  background-color: white;
}

.selection .active {
  width: 10%;
  border: none;
  background-color: #eee;
}

table tbody td input {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

table tbody td select {
  border-radius: 3px;
  background-color: #eee;
  text-align: center;
  height: 25px;
  font-size: small;
}

.body4 button {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
  font-size: small;
}

.option {
  text-align: center;
}

.input-group-text {
  width: 20%;
  border: none;
}
</style>
