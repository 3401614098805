<template>
    <div class="page">
        <div class="head-title">
            <div class="left">
                <ul class="breadcrumb">
                    <li>
                        <a href="#">{{ $t("management") }} {{ $t("projects") }}</a>
                    </li>
                    <li>-</li>
                    <li>
                        <router-link to="" class="active">{{ $t("receive") }} {{ $t("petty") }}</router-link>
                    </li>
                    <li>-</li>
                    <li>
                        <router-link to="" class="active">
                            {{ $t("petty") }} - {{ this.$route.params.id }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="main">
            <div class="head">
                <div class="head1">
                    <h3>
                        <i class="bi bi-credit-card"></i> {{ $t("receive") }} {{ $t("petty") }}
                    </h3>
                </div>
                <div class="head2">
                    <button class="buttonconfirm" type="submit" :disabled="disabled" @click="submit"
                        v-if="alldata.status == 'waiting'">
                        {{ $t("confirm") }}
                    </button>
                </div>
            </div>
            <div class="body">
                <div class="body1">
                    <div class="group">
                        <div class="row mb-2">
                            <label for="inputEmail3" class="col-sm-2 col-form-label-sm">{{ $t("owner") }} <span
                                    class="error-feedback">{{
                                        "*"
                                    }}</span>
                            </label>
                            <div class="col-sm-4">
                                <input type="text" disabled class="form-control" v-model="alldata.ownername" />
                            </div>
                        </div>
                        <div class="row mb-1">
                            <label class="col-sm-2 col-form-label">{{ $t("date") }} {{ $t("payment") }}
                                <span class="error-feedback">{{
                                    "*"
                                    }}</span></label>
                            <div class="col-sm-3">
                                <input type="date" disabled class="form-control" v-model="alldata.paydate" />
                            </div>
                        </div>
                        <div class="row mb-1">
                            <label class="col-sm-2 col-form-label">{{ $t("amount") }}
                                <span class="error-feedback">{{
                                    "*"
                                    }}</span></label>
                            <div class="col-sm-3">
                                <input type="text" disabled class="form-control" v-model="alldata.amount" />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="body1">
                    <div class="group">
                        <div class="row mb-2">
                            <label class="col-sm-2 col-form-label-sm">
                                <i class="bi bi-pin-angle-fill"></i> {{ $t("add") }}
                                {{ $t("attachments") }}
                            </label>
                            <div class="col-sm-8">
                                <div>
                                    <div>
                                        <input type="file" id="files" ref="files" multiple
                                            v-on:change="handleFilesUpload()" />
                                    </div>
                                    <br />
                                    <table class="table" style="width: 100%">
                                        <thead>
                                            <tr>
                                                <th>{{ $t("name") }}</th>
                                                <th>{{ $t("cancel") }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(file, key) in files" :key="file">
                                                <td>{{ file.name }}</td>
                                                <td>
                                                    <a href="#" class="remove-file" v-on:click="removeFile(key)"><i
                                                            class="bi bi-x-circle-fill"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="group">
                    <h5>{{ $t("notes") }}</h5>
                    <textarea disabled v-model="alldata.description" id="" style="width: 100%" rows="3"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios";
import store from "@/store";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
    props: ["css"],

    async mounted() {
        const result1 = await axios.get(`/projects/getreceivepatty/${this.$route.params.id}`);
        if (result1.status == 200) {
            this.alldata = result1.data.data[0];
        }
    },
    data() {
        return {
            alldata: "",
        };
    },
    methods: {
        async submit() {
            this.fileURL = []
            let uploadedFiles = this.$refs.files.files;
            for (var x = 0; x < uploadedFiles.length; x++) {
                const type = uploadedFiles[x].name.split(".");
                this.fileURL.push({ name: uploadedFiles[x].name, type: type[1] });
            }
            Swal.fire({
                title: this.$t("confirm") + "-" + this.$t("payment"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("confirm"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const action = await axios.post("/projects/confirmreceivepatty", {
                        username: store.state.randp.username,
                        id: this.$route.params.id
                    });
                    if (action.status == 200) {
                        // const id = action.data.data
                        // for (var i = 0; i < this.files.length; i++) {
                        //     let file = this.files[i];
                        //     let formData = new FormData();
                        //     let name = this.files[i].name.split(".");
                        //     let newname = store.state.cnum[0].cnum + "_" + id + "_" + i + "." + name[name.length - 1];
                        //     // this.fileURL.push({ name: newname });
                        //     formData.append("file", file, newname);
                        //     await axios.patch(`/receiptowner/${i}`, formData);
                        // }
                        Swal.fire(this.$t("done") + " " + this.$t("payment"));
                        this.redirectTo({ val: "AllReceivePatty" });
                    }
                }
            });

        },
        addFiles() {
            this.$refs.files.click();
        },
        handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
            }
        },
        removeFile(key) {
            this.files.splice(key, 1);
        },
        ...mapActions(["redirectTo"]),
    },

};
</script>
<style lang="scss" scoped>
.page {
    direction: v-bind("css.EN");
    padding: 0.5%;
}

.main {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1%;
}

.body {
    padding: 1%;
    // min-height: 500px;
    //   border: 1px solid black;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.body1 {
    padding: 1%;
    width: 100%;
    min-height: 100px;
    border: 1px solid black;
    display: flex;
    border-radius: 5px;
}

.body2 {
    width: 100%;
    min-height: 100px;
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
}

.body3 {
    width: 100%;
    min-height: 100px;
}

.body4 {
    padding: 1%;
    width: 100%;
    min-height: 100px;
    background-color: #eee;
}

.part {
    font-size: large;
    width: 99%;
    padding: 2%;
    margin: 0.5%;
    background-color: white;
    border-radius: 5px;
    color: black;
    border: 1px solid black;
    font-size: small;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul {
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.bi {
    margin: 1%;
}

.head {
    padding: 0.25%;
    display: flex;
    border-bottom-style: ridge;
}

.head1 {
    width: 60%;
}

.head1 input {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
}

.head2 {
    width: 40%;
    text-align: center;
}

.head2 button {
    margin: 0.5%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
    width: 10%;
}

.head2 button a {
    color: white;
}

.group {
    width: 100%;
}

.group2 {
    width: 100%;
    background-color: #eee;
    display: flex;
}

.group3 {
    width: 100%;
    background-color: #eee;
    display: flex;
}

.group input {
    width: 80%;
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

.group2 input {
    width: 100%;
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

.group3 input {
    width: 50%;
    border: none;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
}

#preview {
    width: 50%;
}

#preview img {
    width: 100%;
    max-height: 200px;
}

.selection button {
    width: 10%;
    border: none;
    background-color: white;
}

.selection .active {
    width: 10%;
    border: none;
    background-color: #eee;
}

table tbody td input {
    border-radius: 3px;
    background-color: #eee;
    text-align: center;
    height: 25px;
    font-size: small;
}

table tbody td select {
    border-radius: 3px;
    background-color: #eee;
    text-align: center;
    height: 25px;
    font-size: small;
}

.body4 button {
    margin: 0.5%;
    padding: 0.5%;
    border: none;
    border-radius: 5px;
    background-color: rgba(98, 153, 174, 255);
    color: white;
    font-size: small;
}

.option {
    text-align: center;
}

.input-group-text {
    width: 20%;
    border: none;
}
</style>