<template>
  <div class="head-title">
    <div class="left">
      <ul class="breadcrumb">
        <li>
          <router-link to="/purchases/home" class="active">{{
            $t("purchases")
          }}</router-link>
        </li>
        <li>-</li>
        <li>
          <a class="active">{{ $t("tax") }} {{ $t("purchases") }} {{ $t("owners")
            }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="main">
    <div class="head">
      <div class="head1">
        <span>{{ $t("owner") }}</span> &nbsp;&nbsp;
        <v-select :options="accounts" label="name" v-model="item"
          style="background-color: white; color: black; width: 40%">
          <template #option="option">
            <span style="color: black"> {{ option.name }}</span>
          </template> </v-select>&nbsp;&nbsp;
        {{ $t("from") }}
        <input type="date" :placeholder="$t('from')" v-model="from" />
        {{ $t("to") }}
        <input type="date" :placeholder="$t('to')" v-model="to" />
        <button class="buttonconfirm" type="submit" @click="submit">
          {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="body" v-if="this.view == 0">برجاء اختيار اسم العميل</div>
    <div class="body" id="main" v-if="fromdate && todate">
      <br />
      <div :style="{ direction: css.EN }">
        <h3>{{ $t("cost") }} {{ $t("projects") }}</h3>
        <h6>{{ $t("from") }} : {{ fromdate }} {{ $t("to") }} : {{ todate }}</h6>
        <table class="table">
          <thead style="position: static">
            <tr>
              <th>رقم الفاتورة الضريبية</th>
              <th>رقم الفاتورة</th>
              <th>التاريخ</th>
              <th>المورد</th>
              <th>الرقم الضريبي للمورد</th>
              <th>الاصناف</th>
              <th>مبلغ المشتريات</th>
              <th>ضريبة المشتريات</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="x in data1" :key="x">
              <td>{{ x.invnum }}</td>
              <td>
                <router-link :to="'/property/RevenuesReportDetails/' +
                  x.actnum +
                  '/' +
                  fromdate +
                  '/' +
                  todate
                  ">{{ x.bill }}
                </router-link>
              </td>
              <td>{{ x.date }}</td>
              <td>{{ x.supname }}</td>
              <td>{{ x.taxnumber }}</td>
              <td>{{ x.item }}</td>
              <td>{{ x.amount }}</td>
              <td>{{ x.tax }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="6">{{ $t("total") }}</td>
              <td>{{ Number(amount).toLocaleString() }}</td>
              <td>{{ Number(tax).toLocaleString() }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import vSelect from "vue-select";
import html2pdf from "html2pdf.js";
export default {
  components: {
    vSelect,
  },
  async mounted() {
    const result2 = await axios.get("/data/allowners");
    if (result2.status == 200) {
      this.accounts = result2.data.data;
    }
  },
  props: ["css"],
  data() {
    return {
      item: "",
      fromdate: "",
      todate: "",
      data1: [],
      view: "",
      namear: "",
      nameen: "",
      typeact: "",
      accounts: [],
      openbalance: "",
      totalbalance: 0,
      totaldpt: 0,
      totalcrd: 0,
      from: "",
      to: "",
      tax: ""
    };
  },
  methods: {
    async submit() {
      if (this.from && this.to) {
        const result = await axios.post(`/projects/taxreportowner`, {
          datefrom: this.from,
          dateto: this.to,
          item: this.item.cid,
        });
        if (result.status == 200) {
          // (this.parent = ""), (this.parent2 = "");
          this.todate = this.to;
          this.fromdate = this.from;
          this.data1 = result.data.data;
          this.amount = this.data1.reduce(
            (acc, array) => acc + Number(array.amount),
            0
          );
          this.tax = this.data1.reduce(
            (acc, array) => acc + Number(array.tax),
            0
          );
          this.commission = this.data1.reduce(
            (acc, array) => acc + Number(array.commission),
            0
          );
        }
      }
    },
    pdf() {
      html2pdf(document.getElementById("main").innerHTML, {
        margin: 0.1,
        filename: `Statement-${this.item.name}.pdf`,
        image: { type: "jpeg", quality: 100 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    print2() {
      this.$htmlToPaper("main");
    },
  },
  watch: {
    filter() {
      this.accounts = this.accounts.filter((x) =>
        x.namear.includes(this.filter.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  // direction: v-bind("css.EN");
  padding: 0.5%;
}

.main {
  width: 98%;
  margin: 1%;
  padding: 1%;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  border: ridge 0.5px;
}

.head {
  padding: 0.25%;
  display: flex;
  border-bottom-style: ridge;
}

.head1 {
  width: 70%;
  display: flex;
}

.head1 input {
  margin: 0.5%;
  padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

.head2 {
  width: 30%;
  text-align: center;
}

.head2 button {
  margin: 0.5%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: rgba(98, 153, 174, 255);
  color: white;
}

.head2 button a {
  color: white;
}

.table {
  margin-top: 1%;
}

table thead tr {
  border-radius: 5px;
  background-color: #eee;
}

select {
  width: 15%;
  margin: 0.5%;
  // padding: 0.5%;
  border: none;
  border-radius: 5px;
  background-color: #eee;
}

@media screen and (max-width: 576px) {
  .table {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: white;
    font-size: xx-small;
  }
}
</style>
